import GA4Helper from "./GA4Helper";

declare global {
    interface Window {
        ga4Helper: any;
        dataLayer: any;
    }
}

//todo
export interface ECommerceItem {
    item_id: string;
    item_name: string;
    currency: string;
    discount: number;
    index: number;
    item_category: string;
    item_category2: string;
    item_category3: string;
    item_category4: string;
    item_category5: string;
    price: number;
    quantity: number;
}

window.ga4Helper = new GA4Helper();