import * as fingerprint from './fingerprint';
import NumberHelper from './../../Shared/Helpers/numberHelper';

NumberHelper.formatDecimals(document);

// Append browser fingerprint to all forms
document.querySelectorAll('form').forEach(form => {
    let input = document.createElement('input') as HTMLInputElement;
    input.type = 'hidden';
    input.name = 'fingerprint';
    input.value = fingerprint.getFingerprint();
    form.appendChild(input);
});

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    $('select')
        .removeAttr('styleme');
}