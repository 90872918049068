export default class GA4Helper {
    private dl: any;
    private items: any[];
    public beginCheckoutItems: any[];

    public initialize(dl: any) {
        this.dl = dl;
    }

    public userId(uId: number) {
        this.dl.push({ 'user_id': uId });
    }

    public onViewItemList(items: []) {
        this.items = items;
        this.pushEvent({
            event: "view_item_list",
            ecommerce: {
                product_id: (items as any)[0].Id,
                product_name: (items as any)[0].Name,
                items: items
            }
        })

        const products = document.getElementsByClassName('product-list-item');
        const t = this;
        for (let i = 0; i < products.length; i++) {
            const a  = products[i] as HTMLLinkElement;
            const index = parseInt(a.dataset.index);
            a.onclick = function() {
                t.onListItemSelected(t.items[index]);
            };
        }
    }

    public onListItemSelected(item: any) {
        this.pushEvent({
            event: "select_item",
            ecommerce: {
                items: [ item ]
            }
        });
    }
    public onViewMoneyTransferProduct(
        pageType: string,
        sku: string,
        senderCountry: string,
        receiverCountry: string) {
        this.pushEvent({
            event: "view_moneytransfer_product",
            product: sku,
            page_type: pageType,
            sender_country: senderCountry,
            receiver_country: receiverCountry,
        });
    }

    public onViewTopupProduct(
        pageType: string,
        sku: string,
        senderCountry: string,
        receiverCountry: string) {
        this.pushEvent({
            event: "view_topup_product",
            product: sku,
            page_type: pageType,
            sender_country: senderCountry,
            receiver_country: receiverCountry,
        });
    }

    public onBeginCheckout(){
        const items = (<any>window).ga4HelperBeginCheckoutItems;
        this.items = items;
        this.pushEvent({
            event: "begin_checkout",
            ecommerce: {
                currency: (items as any)[0].currency,
                value: (items as any)[0].price,
                items: items
            }
        });
    }

    public onAddPaymentInfo(payment_type: string, currency: string, items: any[]) {
        this.items = items;
        this.pushEvent({
            event: "add_payment_info",
            ecommerce: {
                currency: currency,
                value: items[0].price,
                payment_type: payment_type,
                items: items
            }
        });
    }
    
    private pushEvent(event: any) {
        this.dl.push({ ecommerce: null });
        this.dl.push(event);
    }
}