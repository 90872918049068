/* The bundled libraries are hidden in their own function scopes. Expose them here */

var thl_cur = 1; // meine bestellungen
var bl_cur = 1; // meine bestellungen


$ = jQuery.noConflict();

jQuery(document).ready(function ($) {

    jQuery('#fat_accordion > h3').on(
        'click', function() {
            if (jQuery(this).next().is(':visible')) {
                jQuery(this).next().slideUp();
            } else {
                jQuery(this).next().slideDown();
            }
        }
    );
    
    
    // Styling certain selects
    $('select.styleme').each(function () {

        // Cache the number of options
        var $this = $(this),
            numberOfOptions = $(this).children('option').length;

        // Hides the select element
        $this.addClass('s-hidden');

        // Wrap the select element in a div
        $this.wrap('<div class="select"></div>');

        // Insert a styled div to sit over the top of the hidden select element
        $this.after('<div class="styledSelect"></div>');

        // Cache the styled div
        var $styledSelect = $this.next('div.styledSelect');

        // Show the first select option in the styled div
        //$styledSelect.text($this.children('option').eq(0).text());


        if($this.val()){
            $styledSelect.text($this.val());
        }
        else{
            // Show the first select option in the styled div
            $styledSelect.text($this.children('option').eq(0).text());
        }
        if($this.val() == 0){
            $styledSelect.text($this.children('option').eq(0).text());
        }

        // Insert an unordered list after the styled div and also cache the list
        var $list = $('<ul />', {
            'class': 'options'
        }).insertAfter($styledSelect);

        // Insert a list item into the unordered list for each select option
        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }

        // Cache the list items
        var $listItems = $list.children('li');

        // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
        $styledSelect.on('click', function (e) {
            e.stopPropagation();

            //check if element is active
            if($(this).hasClass("active")) {
                $styledSelect.removeClass('active');
                $list.hide();
            } else {
                $('div.styledSelect.active').each(function () {
                    $(this).removeClass('active').next('ul.options').hide();
                });
                $(this).toggleClass('active').next('ul.options').toggle();
                $(this).css("color", "black");
            }
        });

        // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
        // Updates the select element to have the value of the equivalent option
        $listItems.on('click', function (e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
            /* alert($this.val()); Uncomment this for demonstration! */

            //find element with class .styleme (select)
            $(this).parent().prev().prev().trigger("change");
        });

        // Hides the unordered list when clicking outside of it
        $(document).on('click', function () {
            $styledSelect.removeClass('active');
            $list.hide();
        });

    });

    /****** My transactions lists *****/
    if($('#transaction_history_actual_list > li').length>3) {
        $('#list_transaction_history .hlistnav.godown').show();
    }

    $(document).on('click','#list_transaction_history .godown-inner',function() {
        var overall = $('#transaction_history_actual_list > li').length;
        if(overall>3) {
            if(thl_cur<=overall-3) {
                thl_cur = thl_cur+3;
                if(thl_cur>overall-3) {
                    thl_cur = overall;
                    $('#list_transaction_history .hlistnav.godown').hide();
                }
                if(overall>3) {
                    $('#list_transaction_history .hlistnav.goup').show();
                }
                $("#transaction_history_actual_list").scrollTop((thl_cur-1)*130);
                
            }
        }
    });
    
    $(document).on('click','#list_transaction_history .goup-inner',function() {
        var overall = $('#transaction_history_actual_list > li').length;
        if(overall>3) {
            if(thl_cur>1) {
                thl_cur = thl_cur-3;
                if(thl_cur<=1) {
                    thl_cur = 1;
                    $('#list_transaction_history .hlistnav.goup').hide();
                }
                if(overall>3) {
                    $('#list_transaction_history .hlistnav.godown').show();
                }
                $("#transaction_history_actual_list").scrollTop((thl_cur-1)*130);
            }
        }
    });
    
    if($('#banking_actual_list > li').length>3) {
        $('#list_online_banking .hlistnav.godown').show();
    }
    
    $(document).on('click','#list_online_banking .godown-inner',function() {
        var overall = $('#banking_actual_list > li').length;
        if(overall>3) {
            if(thl_cur<=overall-3) {
                thl_cur = thl_cur+3;
                if(thl_cur>overall-3) {
                    thl_cur = overall;
                    $('#list_online_banking .hlistnav.godown').hide();
                }
                if(overall>3) {
                    $('#list_online_banking .hlistnav.goup').show();
                }
                $("#banking_actual_list").scrollTop((thl_cur-1)*180);
                
            }
        }
    });
    
    $(document).on('click','#list_online_banking .goup-inner',function() {
        var overall = $('#banking_actual_list > li').length;
        if(overall>3) {
            if(thl_cur>1) {
                thl_cur = thl_cur-3;
                if(thl_cur<=1) {
                    thl_cur = 1;
                    $('#list_online_banking .hlistnav.goup').hide();
                }
                if(overall>3) {
                    $('#list_online_banking .hlistnav.godown').show();
                }
                $("#banking_actual_list").scrollTop((thl_cur-1)*180);
            }
        }
    });
    

    /*
     * Mobile Language selector in Header
     */
    $('#hmls_actual').on('change', function() {
        window.location.href = $(this).val();
    });
    
    /* Mobile menu */
    $('#trigger-mobile-menu').on('click', function(e) {
        e.stopPropagation();
        $('#slide-out').animate({'right': '0'});
    });
    
    $(document).on('click', function(event) {
        if(!$(event.target).closest('#slide-out').length && !$(event.target).is('#slide-out')) {
            $('#slide-out').animate({'right': '-250px'});
        }
    });

    // Payment dropdown
    $('.payment-dropdown a').on('click', function(event) {
        event.preventDefault();
        $('.payment-dropdown ul').fadeToggle();
    });

    // Top menu click on link opens submenu
    $('.top-menu li a').on('click', function() {
      $(this).siblings('.sub-menu').toggleClass('active');
    });

    // Custom upload
    $('.upload').on('change', function() {
        var filename = $(this).val().split(/(\\|\/)/g).pop();
        $(this).parents().eq(2).next().find('.file-meta').show().find('.filename').text(filename);
        var extension = filename.split('.').pop().toLowerCase();
        var allowed = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];
        var form = this.getAttribute('data-type');
        if(allowed.indexOf(extension) != -1) {
            jQuery("#error-message-passport").hide();
            $('#'+form+'-verification').show();
        } else {
            jQuery("#error-message-passport").show();
            $('#'+form+'-verification').hide();
        }
    });

    $('.file-action').on('click', function(e) {
        e.preventDefault();
        $(this).parents('.file-field').find('.upload').val('');
        $(this).parent('.file-meta').hide();
        var form = this.getAttribute('data-type');
        $('#'+form+'-verification').hide();
    });

    // Faq opening
    $('.faq h5').on('click', function() {
        $(this).next().slideToggle();
    });

    //Anchor opening
    var anchor = location.hash;
    if(!!anchor && !!$(anchor).length){
        $(anchor).click();
        $(anchor)[0].scrollIntoView({
            behavior: 'smooth'
        });
    }

    // Open suggestions
    $('#openSuggestion').on('click', function(event) {
        $(".typeahead").focus();
    });

    // Tooltip
    $('.tooltip').on( {
        mouseenter: function() { $(this).html('<div class="man_tooltip">'+$(this).attr('title')+"</div>"); },
        mouseleave: function() { $('.man_tooltip').remove(); }
    });


    $('.choosing ul li').on('click', function(event) {
        $(this).toggleClass('active').siblings().removeClass('active');
        document.getElementById('button-checkout').setAttribute('data-value', $(this).data('send'));

        var amountElem = $('#amount');
        amountElem.html($(this).data('end')+' '+amountElem.html().split(' ')[1]);

        var receivedElem = $('#received');
        if (receivedElem) {
            var receivedHtml = receivedElem.html();
            if (receivedHtml)
                receivedElem.html($(this).data('value') + ' ' + receivedHtml.split(' ')[1]);
        }

        var ipcvAmount = $('#ipcvAmount');
        if (ipcvAmount) {
            var ipcvAmountHtml = ipcvAmount.html();
            if (ipcvAmountHtml)
                ipcvAmount.html($(this).data('ipcv')+' '+ipcvAmountHtml.split(' ')[1]);
        }
    });

    // Datepicker
   if ($('.datepicker').length) {
       $('.datepicker').pickadate({
           selectMonths: true, // Creates a dropdown to control month
           selectYears: 100, // Creates a dropdown of 15 years to control year
           format: 'mm/dd/yyyy',
           max: new Date()
       });
   };

    // Scroll header
    $(document).on("scroll", function() {

        if ($(document).scrollTop() > 70) {
            $(".header").addClass("fixed");
            $(".header .logo").removeClass('hidden-logo');
            $(".scroll-item-to-hidden").addClass("hidden");
        } else {
            $(".header").removeClass("fixed");
            $(".header .logo").addClass('hidden-logo');
            $(".scroll-item-to-hidden").removeClass("hidden");
        }

    });


    // On click add preloader
    $('.load-more').on('click', function() {
        if ($('.iban-check').val() == 0) {
            $('.warning-container').html('<div class="col s12 card warning">' + Translator.translate('We were unable to match IBAN and recipients name that you entered. Please check your entries and try again.') + '</div>');
        } else if($('.email-check').val() == 0) {
            $('.warning-container').html('<div class="col s12 card warning">' + Translator.translate('Type your phone number.') + '</div>');
        } else {
            $('.warning-container').hide();
        }

    });

    // Loading
window.renderProgress = function (progress) {
        progress = Math.floor(progress);

        if (progress < 25) {
            var angle = -90 + (progress / 100) * 360;
            $(".animate-0-25-b").css("transform", "rotate(" + angle + "deg)");
        } else if (progress >= 25 && progress < 50) {
            var angle = -90 + ((progress - 25) / 100) * 360;
            $(".animate-0-25-b").css("transform", "rotate(0deg)");
            $(".animate-25-50-b").css("transform", "rotate(" + angle + "deg)");
        } else if (progress >= 50 && progress < 75) {
            var angle = -90 + ((progress - 50) / 100) * 360;
            $(".animate-25-50-b, .animate-0-25-b").css("transform", "rotate(0deg)");
            $(".animate-50-75-b").css("transform", "rotate(" + angle + "deg)");
        } else if (progress >= 75 && progress <= 100) {
            var angle = -90 + ((progress - 75) / 100) * 360;
            $(".animate-50-75-b, .animate-25-50-b, .animate-0-25-b").css("transform", "rotate(0deg)");
            $(".animate-75-100-b").css("transform", "rotate(" + angle + "deg)");
        }
        if (progress == 100) {}
        $(".text").html(progress + "%");
    }

 window.clearProgress = function () {
        $(".animate-75-100-b, .animate-50-75-b, .animate-25-50-b, .animate-0-25-b").css("transform", "rotate(90deg)");
    }

    var i = 0;
    var times = 0;

    var interval = setInterval(function() {
        i++;
        times += 1;
        if (times >= 100) {
            // clearProgress();
            var width = '8px';
            clearInterval(interval);
            $('.text').animate({
                opacity: 0.5
            }, 'slow');
            $('.loader-spiner').animate({
                borderLeftWidth: width,
                borderTopWidth: width,
                borderRightWidth: width,
                borderBottomWidth: width
            }, 100);
        }
        renderProgress(i);
    }, 30);
    
    //$(document).on('click','input,textarea,select',function() {
    //   if($(this).next().hasClass('validation-advice')) {
    //       $(this).next().hide();
    //   }
    //});
    
 
});



jQuery(function() {
var $=jQuery;
    $(".youtube").each(function() {
        // Based on the YouTube ID, we can easily find the thumbnail image
                var width=$$('.youtube')[0].getWidth();
                var height=360;
                var thumb_img ='sd';
                $(this).width(640);
                if (width < 640) {
                        $(this).width(width);
                        thumb_img = 'hq';
        } 

                if (width < 320) {
                        $(this).width(width);
                        thumb_img = 'mq';
        } 
                if (width<640){
                        var height=width*5/8}else {height=360}
                        ;
                console.log(width,height,thumb_img);
                $(this).height(height);
        $(this).css('background-image', 'url(https://i.ytimg.com/vi/' + this.id + '/' + thumb_img + 'default.jpg)');

        // Overlay the Play icon to make it look like a video player
        $(this).append($('<div/>', {'class': 'play'}));

        $(document).delegate('#'+this.id, 'click', function() {
            // Create an iFrame with autoplay set to true
            var iframe_url = "https://www.youtube.com/embed/" + this.id + "?autoplay=1&autohide=1";
            if ($(this).data('params')) iframe_url+='&'+$(this).data('params');

            // The height and width of the iFrame should be the same as parent
            var iframe = $('<iframe/>', {'frameborder': '0', 'src': iframe_url, 'width': $(this).width(), 'height': $(this).height() })

            // Replace the YouTube thumbnail with YouTube HTML5 Player
            $(this).replaceWith(iframe);
        });
    });
});

window.disableSignInTypeNumber = function (textbox) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        textbox.addEventListener(event, function (e) {
            if (['-', '+', 'e','E'].includes(e.key)) e.preventDefault();           
        });
    });
}
