function getFingerprintValue(): any {
    return (<any>window).fingerprintValue;
}
function setFingerprintValue(value: any) {
    (<any>window).fingerprintValue = value;
}
export function getFingerprint(): string {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    let fp = getFingerprintValue();
    if(fp === '') {
        const FpClass = (<any>window).Fingerprint;
        fp = typeof FpClass === 'undefined' || FpClass === null
            ? (S4() + S4() + S4() + S4().substr(0, 3) + S4() + S4() + S4() + S4()).toLowerCase()
            : new FpClass().get();
        setFingerprintValue(fp);
    }
    return fp;
}
setFingerprintValue('');

//(<any>window).getFingerprint = getFingerprint;

//export { getFingerprint as getFingerprint };