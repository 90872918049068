import * as Decimal from "decimal.js";

export default class NumberHelper
{
   static disableSignInTypeNumber (textbox: any) {
       if(!textbox)
           return;

        ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
            textbox.addEventListener(event, function (e: any) {
                if (['-', '+', 'e', 'E'].includes(e.key)) e.preventDefault();
            });
        });
    }

    static isNumber (c: any) {
        return c >= '0' && c <= '9';
    }


    static toNumber (n: any, p: number) {
        var str = '';
        var tmp = n.toString();
        for (var i = 0; i < tmp.length; i++) {
            if (this.isNumber(tmp[i])) {
                str += tmp[i];
            }
        }
        var power = isNaN(p) || p === null ? 2 : p;
        return Number(str) / Math.pow(10, power);
    }


    static formatAmount (amount: string) {
        return this._numbertoLocalCulture(amount, 2);
    }

    static getThousandSeparator()
    {
        return (1111).toLocaleString().replace(/1/g, '');
    }

    static getDecimalSeparator()
    {
       return (1.1).toLocaleString().replace(/1/g, '');
    }

    static _numbertoLocalCulture(number: any, digitsAfterComma: number) {
        digitsAfterComma = isNaN(digitsAfterComma) ? 2 : digitsAfterComma;
        var amount = this.toNumber(number, null);
        return amount.toLocaleString((window.navigator as any).userLanguage || window.navigator.language || "en-US",
            { minimumFractionDigits: digitsAfterComma, maximumFractionDigits: digitsAfterComma });
    }

    static toInvariantCultureNumberString (value: any): number | string | Decimal.Decimal {
        if (typeof value == 'number' || Decimal.Decimal.isDecimal(value))
            return value;

        let strValue = value;
        if (typeof value != 'string') {
            strValue = value.toString();
        }
        const str = strValue.replace(/\s/g, '');
        return NumberHelper.parseFloatOpts(str);
    };

    static getFixedDigits(senderCurrency: string)
    {
        var digits = 4;
        if (senderCurrency === "CLP")
            digits = 6;

        return digits;
    }

    static parseFloatOpts = function (str: string) {
        if (typeof str === "number") {
            return str;
        }
        const ar = str.split(/[.,]/);
        let value = '';
        for (let i = 0; i < ar.length; i++) {
            if (i > 0 && i == ar.length - 1) {
                value += ".";
            }
            value += ar[i];
        }
        return Number(value);
    }

    static roundUp (d: Decimal.Decimal): Decimal.Decimal {
        return d.mul(100).ceil().div(100);
    };

    static roundDown (d: Decimal.Decimal): Decimal.Decimal {
        return d.mul(100).floor().div(100);
    };

    static formatDecimals (parentElement: ParentNode): void {
        parentElement = parentElement || document;

        // Auto-format decimals to current locale
        parentElement.querySelectorAll('[data-formatAmountToLocale]').forEach(element => {
            let parsedNumber = parseFloat(element.innerHTML);

            if (!isNaN(parsedNumber)) {
                let min = 0;
                let max = 2;

                let precision = parseInt(element.attributes.getNamedItem("data-formatAmountToLocale").value, 10);
                if (!isNaN(precision)) {
                    min = precision;
                    max = precision;
                }

                const ServerGlobals = ((window as any).FmGlobals || {}) as any; // This should be sent by the server to the client

                element.innerHTML = parsedNumber.toLocaleString(ServerGlobals.defaultShopLocale || undefined, { minimumFractionDigits: min, maximumFractionDigits: max });
            }
        });
    };
}